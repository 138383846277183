import React from 'react';
import { motion } from 'framer-motion';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Container, Typography, Stack, useMediaQuery } from '@material-ui/core';
import Lottie from 'react-lottie';
// routes
//
import { varFadeInUp, varWrapEnter, varFadeInRight, MotionInView } from '../../animate';
import SettingColor from '../../settings/SettingColor';
import { lottieOptions } from '../../settings/lottieOptions';
import lottieData from '../../../lottie/prozoft.json';
// ----------------------------------------------------------------------

const LoaderStyle = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  zIndex: 1000000
}));

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[200],

  [theme.breakpoints.up('xl')]: {
    top: 0,
    left: 0,
    width: '100%',
    position: 'fixed',
    alignItems: 'center',
    overflow: 'auto',
    height: '90vh'
  },
  [theme.breakpoints.down('xl')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    position: 'fixed',
    alignItems: 'flex-start',
    overflow: 'auto',
    paddingTop: 50,
    paddingBottom: 40
  },
  [theme.breakpoints.down('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: 'unset !important',
    minHeight: 700,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    overflow: 'hidden',
    paddingBottom: 30,
    paddingTop: 0
  }
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  zIndex: 10,
  maxWidth: 520,
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    textAlign: 'left'
  }
}));

const ProfileImgStyle = styled(motion.img)(({ theme }) => ({
  width: 300,
  zIndex: 10,
  [theme.breakpoints.down('md')]: {
    width: 300,
    alignSelf: 'center'
  },
  [theme.breakpoints.only('sm')]: {
    width: 250,
    alignSelf: 'center'
  },
  [theme.breakpoints.only('xs')]: {
    width: 250,
    alignSelf: 'center'
  },
  [theme.breakpoints.up('lg')]: {
    alignSelf: 'flex-start',
    marginTop: 50
  }
}));

const BrandMobileImgStyle = styled(motion.img)(({ theme }) => ({
  marginTop: 30,
  marginBottom: 0,
  alignSelf: 'center'
}));

const Logo = styled(motion.div)(({ theme }) => ({
  width: 600,
  zIndex: 10,
  top: '10%',
  position: 'absolute',
  [theme.breakpoints.only('xl')]: {
    left: '49%'
  },
  [theme.breakpoints.only('lg')]: {
    left: '48%'
  },
  [theme.breakpoints.only('md')]: {
    display: 'none'
  },
  [theme.breakpoints.only('xs')]: {
    display: 'none'
  },
  [theme.breakpoints.only('sm')]: {
    display: 'none'
  }
}));

// ----------------------------------------------------------------------
const anim = { y: [-15, 5, -15] };
const size = { width: 40, height: 40 };

export default function LandingHero() {
  const theme = useTheme();
  const isTiny = useMediaQuery(theme.breakpoints.down('lg'));
  const [doneLoadingImage, setDoneLoadingImage] = React.useState(false);
  const [shouldPlay, setShouldPlay] = React.useState(false);

  return (
    <>
      {!doneLoadingImage && (
        <LoaderStyle>
          <BrandMobileImgStyle
            style={{ maxWidth: 100, marginTop: -100 }}
            alt="brand"
            src="/static/brand/LogoOnly.svg"
          />
        </LoaderStyle>
      )}
      <RootStyle
        initial="initial"
        animate="animate"
        variants={varWrapEnter}
        sx={{ position: 'relative', overflow: 'hidden' }}
      >
        <Logo>
          {shouldPlay && (
            <Lottie
              height={200}
              options={{
                ...lottieOptions,
                loop: false,
                animationData: lottieData,
                autoplay: true
              }}
            />
          )}
        </Logo>
        <Container
          maxWidth="lg"
          style={{ display: 'flex', alignSelf: 'end' }}
          sx={{ justifyContent: { xs: 'center', lg: 'flex-start' } }}
        >
          <ContentStyle style={{ maxWidth: 700, paddingBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                flexDirection: 'column'
              }}
            >
              {isTiny && doneLoadingImage && (
                <div style={{ height: 150, width: '100%' }}>
                  {shouldPlay && (
                    <Lottie
                      height={150}
                      options={{
                        ...lottieOptions,
                        loop: false,
                        animationData: lottieData,
                        autoplay: true
                      }}
                    />
                  )}
                </div>
              )}
              <ProfileImgStyle
                onLoad={() => {
                  setTimeout(() => setDoneLoadingImage(true), 500);

                  setTimeout(() => {
                    setShouldPlay(true);
                  }, 800);
                }}
                alt="hero"
                src="/static/profile/profile5-min.png"
              />
            </div>

            <motion.div style={{ marginTop: 12 }} variants={varFadeInUp} delay={500}>
              <Typography variant="h2" sx={{ color: 'common.darkgray', textAlign: 'start' }}>
                Hallo, ik ben&nbsp;
                <Typography component="span" variant="h2" sx={{ color: 'primary.main' }}>
                  Nick Prozee
                </Typography>
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight} style={{ marginTop: 8, textAlign: 'start' }}>
              <Typography
                component="span"
                variant="h3"
                sx={{ color: 'common.darkgray', marginTop: 8, fontStyle: 'italic' }}
              >
                “Software Engineer”
              </Typography>
            </motion.div>

            <MotionInView variants={varFadeInUp}>
              <Typography
                component="p"
                variant="overline"
                sx={{ mb: 2, color: 'primary.main', textAlign: 'start', marginTop: 0 }}
              >
                Kies uw favoriete thema :)
              </Typography>
            </MotionInView>
            <motion.div variants={varFadeInRight} style={{ marginTop: 8, maxWidth: 200 }}>
              <SettingColor />
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Typography component="p" variant="overline" sx={{ color: 'common.darkgray', textAlign: 'start' }}>
                Welkom op mijn website
              </Typography>
            </motion.div>

            <Stack direction="row" spacing={3} justifyContent={{ xs: 'flex-start', md: 'flex-start' }}>
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/react.svg"
              />
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0.3 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/vscode.svg"
              />
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0.6 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/visualstudio.svg"
              />
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0.9 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/ic_ts.svg"
              />
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent={{
                xs: 'flex-start',
                md: 'flex-start'
              }}
              style={{ marginTop: 16 }}
            >
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/ic_js.svg"
              />
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0.3 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/api.png"
              />
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0.6 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/playstore.svg"
              />
              <motion.img
                animate={anim}
                transition={{ duration: 4, repeat: Infinity, delay: 0.9 }}
                style={size}
                variants={varFadeInRight}
                src="/static/home/appstore.svg"
              />
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { xl: '90vh', md: '100vh', sm: 'unset' }, minHeight: { sm: 'unset' } }} />
    </>
  );
}
