import React from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { isMobile } from '../utils/isMobile';

const size = { width: 60, height: 60 };
const transparent = { backgroundColor: 'transparent !important' };

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3)
  },
  date: {
    marginTop: '12px !important'
  },
  header: {
    marginBottom: '12px !important',
    color: '#4b5760'
  },
  content: {
    minHeight: 175,
    [theme.breakpoints.down('sm')]: {
      minHeight: 200
    }
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  },
  item: {
    width: '100%'
  },
  dot: {
    ...transparent,
    width: size.width + 4,
    height: size.height + 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  dotImage: size,
  seperator: {
    height: 200
  }
}));

function CustomTimeline(props) {
  const classes = useStyles();

  const timeJSX = (
    <Typography variant="body1" color="textSecondary">
      {props.date}
    </Typography>
  );

  const storyJSX = (
    <Paper elevation={7} className={classes.paper}>
      <Typography variant="h6" component="h1" className={classes.header} color="primary">
        {props.title}
      </Typography>
      <Typography>{props.message}</Typography>
    </Paper>
  );

  return (
    <TimelineSeparator classes={{ root: classes.seperator }}>
      <TimelineItem classes={{ root: classes.item }}>
        <TimelineOppositeContent className={props.left ? classes.content : classes.date}>
          {props.left ? storyJSX : timeJSX}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot className={classes.dot} style={props.style}>
            <img alt="TL" className={classes.dotImage} src={props.src} style={props.style} />
          </TimelineDot>

          {!props.hideSeperator && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent className={props.left ? classes.date : classes.content}>
          {props.left ? timeJSX : storyJSX}
        </TimelineContent>
      </TimelineItem>
    </TimelineSeparator>
  );
}

export default function MyTimeline() {
  return (
    <Timeline>
      <CustomTimeline
        date="2010"
        title="Servicedesk Enterprise Programma"
        message="Mijn eerste bedrijfsapplicatie geschreven voor de servicedesk waar toen in dienst was :)"
        src="/static/icons/code.svg"
      />
      <CustomTimeline
        date="2010 - 2011"
        title="Programmeer Certificaten"
        message="Behalen van SE Java & C# certificaten om kennis verder uit te breiden"
        src="/static/icons/graduate.svg"
      />
      <CustomTimeline
        date="2011 - 2016"
        title="Software Engineer"
        message="Mijn eerste functie als software ontwikkelaar"
        src="/static/icons/code.svg"
        left
      />
      <CustomTimeline
        date="2015 - 2016"
        title="Meer Certificaten"
        message="C# Web Development Certificaten!"
        src="/static/icons/graduate.svg"
      />
      <CustomTimeline
        date="2016 - 2021"
        title="R&D Manager"
        message="Mijn kennis en groei zorgde voor promotie naar R&D manager"
        src="/static/icons/suit.svg"
        left
      />
      <CustomTimeline
        date="2018 - 2020"
        title="Universiteit - Software Architect"
        message="Diploma behaald als Software Architect!"
        src="/static/icons/badge.svg"
        style={{ width: 80, height: 80 }}
      />
      <CustomTimeline
        date="2021 - Heden"
        title="Prozoft IO"
        message="Met trots Prozoft IO opgericht om grote projecten onder handen te nemen"
        src="/static/brand/LogoOnly.svg"
        style={{ width: 100, height: 100 }}
        hideSeperator
        left
      />
    </Timeline>
  );
}
