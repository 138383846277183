// material
import { useTheme, styled, alpha } from '@material-ui/core/styles';
import { Box, Grid, Container, Typography, useMediaQuery } from '@material-ui/core';
import { motion } from 'framer-motion';

import Lottie from 'react-lottie';
import MyTimeline from '../../MyTimeline';
import MyTimelineMobile from '../../MyTimelineMobile';
import { varFadeInUp, MotionInView, varFadeInDown } from '../../animate';
import learningLottie from '../../../lottie/appdev.json';
import programmingLottie from '../../../lottie/programming2.json';
import { lottieOptions } from '../../settings/lottieOptions';

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  backgroundImage: `linear-gradient(180deg, ${alpha(theme.palette.grey[200], 0)} 100%, #fff 0%)`,
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15)
  }
}));

const HeroOverlayStyle = styled(motion.div)(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 1,
  width: 500,
  height: 400,
  margin: 'auto',
  position: 'absolute',
  opacity: 0.3,
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    opacity: 0.6
  }
}));

const HeroLottieStyle = styled(motion.div)(({ theme }) => ({
  bottom: 0,
  zIndex: 0,
  minWidth: 0,
  position: 'absolute'
}));

// ----------------------------------------------------------------------

export default function LandingTimeline() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ zIndex: 10, position: 'relative' }}>
        {isDesktop && (
          <>
            {' '}
            <HeroLottieStyle sx={{ zIndex: -1, top: 0, left: isDesktop ? -200 : 0 }}>
              <Lottie
                style={{ zIndex: 0 }}
                options={{ ...lottieOptions, animationData: programmingLottie }}
                height={400}
                width={500}
              />
              <HeroOverlayStyle />
            </HeroLottieStyle>
            <HeroLottieStyle sx={{ zIndex: -1, top: isDesktop ? 600 : 1000, right: isDesktop ? -400 : 0 }}>
              <Lottie
                style={{ zIndex: 0 }}
                options={{ ...lottieOptions, animationData: learningLottie }}
                height={400}
                width={500}
              />
              <HeroOverlayStyle />
            </HeroLottieStyle>
          </>
        )}

        <Box sx={{ mb: { xs: 20, md: 10 }, mt: { xs: 0, md: 10 }, zIndex: 100 }}>
          <MotionInView variants={varFadeInUp} sx={{ zIndex: 100 }}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: 'primary.main', textAlign: 'center', zIndex: 100 }}
            >
              +{new Date().getFullYear() - 2010} Jaar Ervaring
            </Typography>
            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
                color: 'common.darkgray',
                zIndex: 100
              }}
            >
              Mijn Persoonlijke Roadmap
            </Typography>

            {!isDesktop && (
              <Lottie
                style={{ zIndex: 0, marginTop: 20, marginBottom: -100 }}
                options={{ ...lottieOptions, animationData: programmingLottie }}
                height={200}
                width={250}
              />
            )}
          </MotionInView>
        </Box>

        <Grid spacing={isDesktop ? 10 : 5}>{isDesktop ? <MyTimeline /> : <MyTimelineMobile />}</Grid>
      </Container>
    </RootStyle>
  );
}
