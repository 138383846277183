import React from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { isMobile } from '../utils/isMobile';

const size = { width: 40, height: 40 };
const transparent = { backgroundColor: 'transparent !important' };

const useStyles = makeStyles((theme) => ({
  paper: {
    zIndex: 10,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    position: 'relative'
  },
  date: {
    marginTop: '12px !important'
  },
  header: {
    marginBottom: '12px !important',
    color: '#4b5760',
    zIndex: 5
  },
  content: {
    minHeight: 175
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  },
  item: {},
  dotImage: {
    ...size,
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 4,
    opacity: 0.8
  },
  timelineRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  divider: {
    height: 80,
    width: 2,
    borderRadius: 3,
    marginTop: 12,
    marginBottom: 12,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6
  }
}));

function CustomTimeline(props) {
  const classes = useStyles();

  return (
    <Paper elevation={7} className={classes.paper}>
      <Typography variant="body1" color="textSecondary">
        {props.date}
      </Typography>{' '}
      <Typography variant="h6" component="h1" className={classes.header} color="primary">
        {props.title}
      </Typography>
      <Typography variant="p" component="p" className={classes.header} color="common.darkgray">
        {props.message}
      </Typography>
      <img alt="TL" className={classes.dotImage} src={props.src} style={props.style} />
    </Paper>
  );
}

function Divider() {
  const classes = useStyles();
  return <div className={classes.divider} />;
}

export default function MyTimelineMobile() {
  const classes = useStyles();
  return (
    <div className={classes.timelineRoot}>
      <CustomTimeline
        date="2010"
        title="Servicedesk Enterprise Programma"
        message="Mijn eerste bedrijfsapplicatie geschreven voor de servicedesk waar toen in dienst was :)"
        src="/static/icons/code.svg"
      />
      <Divider />
      <CustomTimeline
        date="2010 - 2011"
        title="Programmeer Certificaten"
        message="Behalen van SE Java & C# certificaten om kennis verder uit te breiden"
        src="/static/icons/graduate.svg"
      />
      <Divider />
      <CustomTimeline
        date="2011 - 2016"
        title="Software Engineer"
        message="Mijn eerste functie als software ontwikkelaar"
        src="/static/icons/code.svg"
        left
      />
      <Divider />
      <CustomTimeline
        date="2015 - 2016"
        title="Meer Certificaten"
        message="C# Web Development Certificaten!"
        src="/static/icons/graduate.svg"
      />
      <Divider />
      <CustomTimeline
        date="2016 - 2021"
        title="R&D Manager"
        message="Mijn kennis en groei zorgde voor promotie naar R&D manager"
        src="/static/icons/suit.svg"
        left
      />
      <Divider />
      <CustomTimeline
        date="2018 - 2020"
        title="Universiteit - Software Architect"
        message="Diploma behaald als Software Architect!"
        src="/static/icons/badge.svg"
      />
      <Divider />
      <CustomTimeline
        date="2021 - Heden"
        title="Prozoft IO"
        message="Met trots Prozoft IO opgericht om grote projecten onder handen te nemen"
        src="/static/brand/LogoOnly.svg"
        hideSeperator
        left
      />
    </div>
  );
}
