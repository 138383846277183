// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Box, Grid, Card, Container, Typography, useMediaQuery } from '@material-ui/core';
import Lottie from 'react-lottie';
import { motion } from 'framer-motion';
import ProgressBar from '../../ProgressBar';
import programmingLottie from '../../../lottie/learning.json';
import { lottieOptions } from '../../settings/lottieOptions';
import { varFadeInUp, MotionInView, varFadeInDown } from '../../animate';

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(25),
  backgroundImage: `linear-gradient(180deg, ${alpha(theme.palette.grey[300], 0)} 0%, ${theme.palette.grey[200]} 100%)`
}));

const HeroLottieStyle = styled(motion.div)(({ theme }) => ({
  bottom: 0,
  zIndex: 0,
  minWidth: 0,
  position: 'absolute'
}));

export default function LandingSkills() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <RootStyle>
      <Container sx={{ position: 'relative', mt: { md: theme.spacing(35), sm: theme.spacing(15) } }} maxWidth="lg">
        {isDesktop && (
          <HeroLottieStyle sx={{ top: -170, left: -150, zIndex: 0 }}>
            <Lottie
              style={{ zIndex: 0, opacity: 0.7 }}
              options={{ ...lottieOptions, animationData: programmingLottie }}
              height={350}
              width={400}
            />
          </HeroLottieStyle>
        )}
        <Box sx={{ mb: { xs: 20, md: 10 }, mt: { xs: 10, md: 10 }, zIndex: 100 }}>
          <MotionInView sx={{ zIndex: 10 }} variants={varFadeInUp}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: 'primary.main', textAlign: 'center', zIndex: 10 }}
            >
              Skills
            </Typography>
          </MotionInView>
          <MotionInView sx={{ zIndex: 10 }} variants={varFadeInDown}>
            <Typography variant="h2" sx={{ textAlign: 'center', color: 'common.darkgray', zIndex: 10 }}>
              Populaire Vaardigheden
            </Typography>
          </MotionInView>

          {!isDesktop && (
            <Lottie
              style={{ zIndex: 0, marginTop: 20, marginBottom: -150 }}
              options={{ ...lottieOptions, animationData: programmingLottie }}
              height={200}
              width={250}
            />
          )}
        </Box>

        <MotionInView variants={varFadeInUp} sx={{ zIndex: 10 }}>
          <Grid container spacing={isDesktop ? 10 : 5} sx={{ zIndex: 10 }}>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={95} delay={200} label="C#" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={95} delay={1500} label=".NET Core" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={79} delay={500} label="Google Admin" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={92} delay={1500} label="React-Native" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={85} delay={500} label="Office365" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={80} delay={500} label="Sharepoint" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={70} delay={800} label="NodeJS" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={93} delay={500} label=".NET Framework" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={91} delay={500} label="Typescript" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={87} delay={500} label="WPF" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={89} delay={500} label="ReactJS" />
            </Grid>
            <Grid item sx={{ zIndex: 10 }} xs={6} md={4}>
              <ProgressBar value={88} delay={500} label="Azure AD" />
            </Grid>
          </Grid>
        </MotionInView>
      </Container>
    </RootStyle>
  );
}
