import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@material-ui/core';
//
import Logo, { LogoOnly } from '../../components/Logo';

// ----------------------------------------------------------------------

const SOCIALS = [{ name: 'Linkedin', icon: linkedinFill, href: 'https://www.linkedin.com/in/nick-prozee-93311a9a/' }];

const LINKS = [
  {
    headline: 'Bedrijf',
    children: [{ name: 'KVK: 78683564' }, { name: 'BTW: NL003371889B07' }]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'Nick@prozoft.io', href: 'mailto:nick@prozoft.io' },
      { name: '+31645134758', href: 'tel:+31645134758' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3}>
            <ScrollLink to="move_top" spy smooth>
              <LogoOnly
                style={{ margin: 'auto', width: 80 }}
                sx={{ selfAlign: 'center', mx: { xs: 'auto', md: 'inherit' } }}
              />
            </ScrollLink>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'center' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                  <a href={social.href}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </a>
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography id={headline} component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) =>
                      link.href ? (
                        <Typography>
                          <a href={link.href}>{link.name}</a>
                        </Typography>
                      ) : (
                        <Typography>{link.name}</Typography>
                      )
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2021. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
