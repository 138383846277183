import React from 'react';
// material
import { useTheme, styled, alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Lottie from 'react-lottie';
import { motion } from 'framer-motion';
import { Box, Grid, Container, Typography, useMediaQuery, Paper, Badge } from '@material-ui/core';
import { varFadeInUp, MotionInView } from '../../animate';
import { lottieOptions } from '../../settings/lottieOptions';
import programmingLottie from '../../../lottie/appdev.json';

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  backgroundImage: `linear-gradient(180deg, ${alpha(theme.palette.grey[300], 0)} 100%, #fff 0%)`,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15)
  }
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 350,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
      minHeight: 450
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: 400
    },

    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  intro: {
    position: 'relative',
    minHeight: 350,
    paddingRight: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  introImg: {
    zIndex: -1,
    position: 'absolute',
    maxHeight: 500,
    left: -200,
    top: -100
  },
  item: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.only('lg')]: {
      alignSelf: 'flex-end'
    }
  },
  badgeContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
    minHeight: 50,
    justifyContent: 'center'
  },
  badge: {
    width: 'auto',
    paddingLeft: 8,
    paddingRight: 8,
    margin: 4,
    borderRadius: 18
  },
  divider: {
    width: 100,
    height: 4,
    marginLeft: 0,
    backgroundColor: theme.palette.primary.main
  }
}));

const HeroLottieStyle = styled(motion.div)(({ theme }) => ({
  minWidth: 0,
  position: 'absolute',
  zIndex: -1
}));

const IntroImage = styled(motion.div)(({ theme }) => ({
  zIndex: -1,
  position: 'absolute',
  width: 500,
  top: -150,
  [theme.breakpoints.only('xl')]: {
    left: -99
  },
  [theme.breakpoints.only('lg')]: {
    left: -50
  },
  [theme.breakpoints.only('md')]: {
    left: -135,
    top: -80
  },
  [theme.breakpoints.only('sm')]: {
    left: -170,
    width: 400,
    top: 0
  },
  [theme.breakpoints.only('xs')]: {
    left: -180,
    width: 400,
    top: -200
  }
}));

export function Intro() {
  const classes = useStyles();

  return (
    <Grid className={classes.item} item xs={12}>
      <MotionInView
        variants={varFadeInUp}
        className={classes.intro}
        sx={{
          height: 'auto',
          alignItems: 'center'
        }}
      >
        <IntroImage>
          <img alt="Nick Prozee" src="/static/profile/profile.png" />
        </IntroImage>

        <Grid
          item
          md={8}
          sm={12}
          sx={{
            width: 'auto',
            alignSelf: {
              sm: 'center',
              md: 'flex-end'
            }
          }}
        >
          <Paper elevation={5} className={classes.paper} style={{ alignSelf: 'flex-start', width: 'auto' }}>
            <Typography component="p" variant="h4" sx={{ padding: 1, color: 'common.darkgray', display: 'flex' }}>
              <Typography component="p" variant="h4" sx={{ color: 'primary.main', mr: 1 }}>
                Nick
              </Typography>
              Prozee
            </Typography>

            <div className={classes.divider} />

            <Typography component="p" variant="p" sx={{ padding: 1, mb: 2, mt: 2, color: '#2c3e50', fontSize: 18 }}>
              <strong style={{ fontStyle: 'italic' }}>Ik ben een gedreven ontwikkelaar</strong>
              <br />
              Altijd op zoek naar nieuwe uitdagingen, hierdoor ben ik met veel talen en platformen in aanraking gekomen.
              Of het nou gaat om Mobiele Apps, Api's, Web Apps en dergelijke, ik doe het allemaal. <br />
              <br />
              <strong style={{ fontStyle: 'italic' }}>
                Ik ben een sterke teamspeler die een wens weet te vertalen naar techniek
              </strong>
              <br />
              Met een juiste architectuur en leesbare code. Ik brainstorm graag met collega's om tot de beste oplossing
              te komen waar iedereen het mee eens is, om betrokkenheid binnen het team te behouden. Termen als Scrum,
              Agile, Git, DevOps etc. zijn mij ook niet vreemd.
            </Typography>
          </Paper>
        </Grid>
      </MotionInView>
    </Grid>
  );
}

export function Card(props) {
  const classes = useStyles();

  return (
    <Grid className={classes.item} item lg={4} md={4} xs={12}>
      <MotionInView variants={varFadeInUp}>
        <Paper elevation={5} className={classes.paper}>
          <Typography
            component="p"
            bold
            variant="overline"
            sx={{ fontWeight: 'bold', mb: 2, mt: 2, color: 'primary.main', textAlign: 'center' }}
          >
            {props.title}
          </Typography>
          <img src={props.image} style={{ height: 100, alignSelf: 'center' }} alt="Mobile App" />
          <div className={classes.badgeContainer}>
            {props.badges.map((badge) => (
              <div className={classes.badge}>
                <Typography component="p" variant="body2" bold sx={{ color: 'common.darkgray' }}>
                  {badge}
                </Typography>
              </div>
            ))}
          </div>
          <Typography
            component="p"
            variant="p"
            sx={{ padding: 1, mb: 2, mt: 2, color: '#2c3e50', textAlign: 'center', fontSize: 18 }}
          >
            {props.description}
          </Typography>
        </Paper>
      </MotionInView>
    </Grid>
  );
}
export default function WhoAmI() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ zIndex: 10, position: 'relative' }}>
        {isDesktop && (
          <HeroLottieStyle sx={{ top: -50, right: -200 }}>
            <Lottie
              style={{ opacity: 0.6 }}
              options={{ ...lottieOptions, animationData: programmingLottie }}
              height={400}
              width={500}
            />
          </HeroLottieStyle>
        )}
        <Box sx={{ mb: { xs: 5, md: 10 }, mt: { xs: 0, md: 10 }, zIndex: 100 }}>
          <MotionInView variants={varFadeInUp} sx={{ zIndex: 100 }}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: 'primary.main', textAlign: 'center', zIndex: 100 }}
            >
              Projecten
            </Typography>
            <Typography
              variant="h2"
              sx={{
                paddingLeft: '15%',
                paddingRight: '15%',
                textAlign: 'center',
                color: 'common.darkgray',
                zIndex: 100,
                mb: { xs: 4, md: 2, sm: 0 },
                mt: { xs: 0, md: 2 }
              }}
            >
              Waar kan ik bijdragen
            </Typography>
          </MotionInView>
        </Box>

        <Grid container spacing={5}>
          <Intro />

          {[
            {
              title: 'Mobile Apps',
              badges: ['React-Native', 'Typescript', 'Javascript', 'Redux', 'Xamarin'],
              image: '/static/icons/mobile-app.svg',
              description: 'Mobile apps ontwikkelen met responsive UX & Design'
            },
            {
              title: "Web API's",
              badges: ['C#', 'ASP .NET', '.NET Core', 'NodeJS', 'TypeScript', 'Entity Framework'],
              image: '/static/home/api.png',
              description: "Ontwikkeling van API's in .NET Core of NodeJS met goede architectuur"
            },
            {
              title: 'Web Apps',
              badges: ['C#', 'ASP .NET', '.NET Core', 'NodeJS', 'TypeScript', 'React', 'Angular', 'OAuth'],
              image: '/static/icons/apps.svg',
              description: 'Web Apps programmeren in verschillende platformen en talen'
            },
            {
              title: 'Desktop Apps',
              badges: ['C#', 'WPF', 'UWP', '.NET', 'Electron', 'React', 'Angular', 'Entity Framework'],
              image: '/static/icons/desktop.svg',
              description: 'Responsive en moderne Desktop Apps'
            },
            {
              title: 'Website',
              badges: ['HTML', 'Sass', 'CSS', 'Javascipt', 'Typescript', 'Wordpress', 'React'],
              image: '/static/icons/dashboard.svg',
              description: 'Mooie websites die een goede en professionele indruk geeft'
            },
            {
              title: 'Databases',
              badges: ['SQL', 'MySQL', 'MongoDB', 'Firebase', 'GraphQL', 'Entity Framework'],
              image: '/static/icons/database.svg',
              description: 'Ontwikkelen van verschillende databases'
            }
          ].map((i) => (
            <Card {...i} />
          ))}

          <Grid item xs={12}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mt: 4, mb: 4, color: 'primary.main', textAlign: 'center', zIndex: 100 }}
            >
              Overige
            </Typography>
          </Grid>

          {[
            {
              title: 'Office365',
              badges: ['C#', 'ASP .NET', '.NET Core', 'NodeJS', 'TypeScript', 'React', 'Angular'],
              image: '/static/icons/office365.png',
              description: 'Ontwikkelingen m.b.t Office365'
            },
            {
              title: 'Google Admin',
              badges: ['C#', 'ASP .NET', '.NET Core', 'NodeJS', 'TypeScript', 'React', 'Angular'],
              image: '/static/icons/google-admin.svg',
              description: 'Ontwikkelingen m.b.t Google Admin'
            },
            {
              title: 'Sharepoint',
              badges: ['C#', 'ASP .NET', '.NET Core', 'NodeJS', 'TypeScript', 'React', 'GraphAPI'],
              image: '/static/icons/sharepoint.svg',
              description: 'Ontwikkelingen m.b.t Sharepoint'
            }
          ].map((i) => (
            <Card {...i} />
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
}
