import { alpha } from '@material-ui/core/styles';
import { Box, Grid, Paper, Radio, RadioGroup, CardActionArea, FormControlLabel } from '@material-ui/core';
// hooks
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------
let color = 'default';

export default function SettingColor() {
  const { themeColor, onChangeColor, colorOption } = useSettings();

  if (themeColor) color = themeColor;

  return (
    <RadioGroup name="themeColor" value={themeColor || color} onChange={onChangeColor}>
      <Grid container spacing={1.5} dir="ltr">
        {colorOption.map((color) => {
          const colorName = color.name;
          const colorValue = color.value;
          const isSelected = themeColor === colorName;

          return (
            <Grid item xs={4} key={colorName}>
              <Paper variant="elevation">
                <CardActionArea
                  onClick={onChangeColor}
                  sx={{ borderRadius: 1, color: colorValue, backgroundColor: '#ecf0f1' }}
                >
                  <Box
                    sx={{
                      height: 12,
                      width: isSelected ? '100%' : 12,
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: colorValue,
                      justifyContent: 'center',
                      borderRadius: 2
                    }}
                  />

                  <FormControlLabel
                    onClick={onChangeColor}
                    label=""
                    value={colorName}
                    control={<Radio sx={{ display: 'none' }} />}
                    sx={{
                      top: 0,
                      margin: 0,
                      width: '100%',
                      height: '100%',
                      position: 'absolute'
                    }}
                  />
                </CardActionArea>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
