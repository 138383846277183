import React from 'react';
// material
import { styled } from '@material-ui/core/styles';
// components
import Page from '../components/Page';
import {
  LandingHero,
  LandingSkills,
  LandingPriorities,
  LandingTimeline,
  WhoAmI
} from '../components/_external-pages/landing';
import MainNavBar from '../layouts/main/MainNavbar';
import MainFooter from '../layouts/main/MainFooter';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)({
  height: '100%'
});

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <RootStyle title="Prozoft IO - Professional Software & Architecture" id="move_top">
      <MainNavBar />
      <LandingHero />

      <ContentStyle>
        <WhoAmI />
        <LandingTimeline />
        <LandingPriorities />
        <LandingSkills />
      </ContentStyle>
      <MainFooter />
    </RootStyle>
  );
}
