// theme
import ThemeConfig from './theme';
// components
import ThemePrimaryColor from './components/ThemePrimaryColor';
import LandingPage from './pages/LandingPage';

// ----------------------------------------------------------------------
export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <LandingPage />
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
