// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import { motion } from 'framer-motion';
import { Box, Grid, Card, Container, Typography, useMediaQuery } from '@material-ui/core';
import { lottieOptions } from '../../settings/lottieOptions';
import { varFadeInUp, MotionInView } from '../../animate';
import programmingLottie from '../../../lottie/circle.json';

// ----------------------------------------------------------------------

const CARDS = [
  {
    icon: '/static/icons/ic_design.svg',
    title: 'UX Design',
    description: 'Het ontwerp moet fijn zijn om naar te kijken, intuïtief en gemakkelijk te gebruiker zijn.'
  },
  {
    icon: '/static/icons/ic_code.svg',
    title: 'Goede Architectuur',
    description:
      'Code dient met goede architectuur ontworpen te zijn om stabiliteit, herbruikbaarheid end leesbaarheid te behouden.'
  },
  {
    icon: '/static/home/c.svg',
    title: 'Programmeer Taal',
    description:
      'Een programmeertaal is een middel om een ​​doel te bereiken. Daarom moeten programmeurs bekend zijn met meerdere talen en geen problemen hebben met het leren van een nieuwe.'
  }
];

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5)
  // [theme.breakpoints.up('md')]: {
  //   paddingBottom: theme.spacing(15)
  // }
}));

const HeroLottieStyle = styled(motion.div)(({ theme }) => ({
  bottom: 0,
  zIndex: 0,
  minWidth: 0,
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginTop: -40
}));

const CardStyle = styled(Card)(({ theme }) => {
  const shadowCard = (opacity) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    maxWidth: 380,
    minHeight: 440,
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(10, 5, 0),
    boxShadow: `-40px 40px 80px 0 ${shadowCard(0.48)}`,
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    '&.cardLeft': {
      [theme.breakpoints.up('md')]: { marginTop: -40 }
    },
    '&.cardCenter': {
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        '&:before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: 0,
          position: 'absolute',
          borderRadius: theme.shape.borderRadiusMd,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`
        }
      }
    }
  };
});

const CardIconStyle = styled('img')(({ theme }) => ({
  width: 40,
  height: 40,
  margin: 'auto',
  marginBottom: theme.spacing(10),
  filter: shadowIcon(theme.palette.primary.main)
}));

// ----------------------------------------------------------------------

export default function LandingPriorities() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ position: 'relative' }}>
        <HeroLottieStyle sx={{ top: -80, left: 0, right: 0 }}>
          <Lottie
            style={{ zIndex: 0 }}
            options={{ ...lottieOptions, animationData: programmingLottie }}
            height={400}
            width={500}
          />
        </HeroLottieStyle>

        <Box sx={{ mb: { xs: 20, md: 10 }, mt: { xs: 10, md: 10 }, zIndex: 100 }}>
          <MotionInView variants={varFadeInUp} sx={{ zIndex: 100 }}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: 'primary.main', textAlign: 'center', zIndex: 100 }}
            >
              Prioriteiten & Visie
            </Typography>
            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
                color: 'common.darkgray',
                zIndex: 100
              }}
            >
              My Visie op Programmeren
            </Typography>
          </MotionInView>
        </Box>

        <Grid container spacing={isDesktop ? 10 : 5}>
          {CARDS.map((card, index) => (
            <Grid key={card.title} item xs={12} md={4}>
              <MotionInView variants={varFadeInUp}>
                <CardStyle className="cardCenter">
                  <CardIconStyle
                    src={card.icon}
                    alt={card.title}
                    sx={{
                      ...(index === 0 && {
                        filter: (theme) => shadowIcon(theme.palette.info.main)
                      }),
                      ...(index === 1 && {
                        filter: (theme) => shadowIcon(theme.palette.error.main)
                      })
                    }}
                  />
                  <Typography variant="h5" paragraph>
                    {card.title}
                  </Typography>
                  <Typography sx={{ color: isLight ? 'text.secondary' : 'common.white' }}>
                    {card.description}
                  </Typography>
                </CardStyle>
              </MotionInView>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
}
