import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { varFadeInUp, MotionInView, varFadeInDown } from './animate';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress sx={{ height: 8 }} variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="primary.main">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

ProgressBar.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
};

export default function ProgressBar(props) {
  const [progress, setProgress] = React.useState(0);

  setTimeout(() => {
    setProgress(props.value);
  }, props.delay);

  return (
    <div style={{ width: '100%' }}>
      <Typography component="p" variant="overline" sx={{ mb: 2, color: 'common.darkgray', textAlign: 'start' }}>
        {props.label}
      </Typography>
      <LinearProgressWithLabel value={progress} />
    </div>
  );
}
